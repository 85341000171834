@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Project Name: Acceede – Helping you better afford the cost of quality education.
    Support: support@acceede.com

-----------------------------------------------------------------------------------*/

/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&amp;display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
}

a {
  text-decoration: none;
}

.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #0e1133;
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

*::-moz-selection {
  background: #2b4eff;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #2b4eff;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #2b4eff;
  color: #ffffff;
  text-shadow: none;
}

.d-flex {
    display: flex !important;
}
.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}
.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 4px solid transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    border-radius: .25rem;
    font-weight: bold !important;
}

.p-t-40 {
  padding-top: 60px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}
/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
  color: #0e1133;
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: #0e1133;
  font-size: 14px;
  opacity: 1;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background: #f3f4f8;
}

.grey-bg-2 {
  background: #edeef3;
}

.grey-bg-5 {
  background: #f5f6fa;
}

.blue-bg {
  background: #2b4eff;
}

.blue-bg-3 {
  background: #b8dde3;
}

.blue-bg-4 {
  background: #2d69f0;
}

.pink-bg {
  background: #dd246e;
}

.purple-bg {
  background: #8007e6;
}

.green-bg {
  background: #0cae74;
}

.white-bg {
  background: #ffffff;
}

.black-bg {
  background: #0e1133;
}

.footer-bg {
  background: #0e1133;
}

/*--
    - color
-----------------------------------------*/
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #ffffff !important;
}

.white-color {
  color: #ffffff;
}

.theme-color {
  color: #2b4eff !important;
}

.black-color {
  color: #0e1133;
}

.pl-245 {
  padding-left: 245px;
}

.pr-245 {
  padding-right: 245px;
}

.mb-22 {
  margin-bottom: 22px;
}

.ml-270 {
  margin-left: 270px;
}

.mb--120 {
  margin-bottom: -120px;
}

.mb--100 {
  margin-bottom: -100px;
}

.mt--210 {
  margin-top: -210px;
}

.mt--80 {
  margin-top: -80px;
}

.mb-130 {
  margin-bottom: -130px;
}
.mb-80 {
  margin-top: 80px !important;
}

.section-padding {
  padding-left: 50px;
  padding-right: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-padding {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .section-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.auth-btn {
  display: inline-block !important;
  text-align: center !important;
  padding: 20px 58px 19px !important;
  color: #ffffff !important;
  background: #2b4eff !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  border-radius: 35px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  text-transform: lowercase !important;
  width: 100%;
}
.e-btn:first-letter {
  text-transform: uppercase;
}
.e-btn:hover {
  color: #ffffff;
  -webkit-box-shadow: 0px 10px 24px 0px rgba(4, 23, 118, 0.3);
  -moz-box-shadow: 0px 10px 24px 0px rgba(4, 23, 118, 0.3);
  box-shadow: 0px 10px 24px 0px rgba(4, 23, 118, 0.3);
}
.e-btn-2 {
  height: 34px;
  line-height: 36px;
  padding: 0 20px;
  font-size: 14px;
  text-transform: capitalize;
}
.e-btn-3 {
  padding: 0 28px;
}
.e-btn-4 {
  height: 46px;
  line-height: 44px;
  border: 2px solid #2b4eff;
  background: #2b4eff;
  color: #ffffff;
}
.e-btn-5 {
  padding-left: 22px;
  padding-right: 22px;
}
.e-btn-6 {
  padding-left: 60px;
  padding-right: 60px;
}
.e-btn-7 {
  height: 44px;
  line-height: 46px;
}
.e-btn-border {
  border: 2px solid #d7d9e3;
  color: #0e1133;
  background: transparent;
}
.e-btn-border:hover {
  background: #2b4eff;
  border-color: #2b4eff;
  color: #ffffff;
}
.e-btn-border-2 {
  height: 46px;
  line-height: 44px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background: transparent;
  padding: 0 20px;
}
.e-btn-border-2:hover {
  background: #ffffff;
  border-color: #ffffff;
  color: #0e1133;
}
.e-btn-white {
  height: 54px;
  line-height: 58px;
  background: #ffffff;
  color: #0e1133;
  padding: 0 45px;
}
.e-btn-white:hover {
  color: #0e1133;
}

/* link btn */
.link-btn {
  position: relative;
  font-size: 16px;
  color: #6d6e75;
  font-weight: 500;
  padding-right: 21px;
  display: inline-block;
}
.link-btn i {
  font-size: 14px;
  position: absolute;
  top: 12px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
}
.link-btn i:first-child {
  right: 10%;
  visibility: hidden;
  opacity: 0;
}
.link-btn i:last-child {
  right: 0;
}
.link-btn:hover {
  color: #2b4eff;
}
.link-btn:hover i:first-child {
  right: 0;
  visibility: visible;
  opacity: 1;
}
.link-btn:hover i:last-child {
  right: -10%;
  visibility: hidden;
  opacity: 0;
}
.link-btn.link-prev {
  padding-right: 0;
  padding-left: 21px;
}
.link-btn.link-prev i:first-child {
  left: 10%;
  right: auto;
}
.link-btn.link-prev i:last-child {
  left: 0;
  right: auto;
}
.link-btn.link-prev:hover i:first-child {
  left: 0%;
  right: auto;
}
.link-btn.link-prev:hover i:last-child {
  left: -10%;
  right: auto;
}

.link-btn-2 {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: #0e1133;
  text-transform: uppercase;
  padding-right: 25px;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 36px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
}
.link-btn-2 i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
}
.link-btn-2 i:first-child {
  left: -100%;
  visibility: hidden;
  opacity: 0;
}
.link-btn-2 i:last-child {
  right: 35%;
}
.link-btn-2:hover {
  color: #ffffff;
}
.link-btn-2:hover i:first-child {
  left: 35%;
  visibility: visible;
  opacity: 1;
}
.link-btn-2:hover i:last-child {
  right: -100%;
  visibility: hidden;
  opacity: 0;
}

.play-btn {
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 47px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  color: #2b4eff;
  -webkit-animation: pulse 2s infinite;
  -moz-animation: pulse 2s infinite;
  -o-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  font-size: 12px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.section__sub-title {
  font-size: 18px;
  font-weight: 500;
  color: #2b4eff;
  display: inline-block;
  margin-bottom: 5px;
}
.section__title {
  font-size: 35px;
  color: #0e1133;
  text-transform: capitalize;
  z-index: 1;
  margin-bottom: 3px;
  font-family: "Poppins", sans-serif !important;
}
.d-sm-flex {
  display: flex !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.align-items-center {
  align-items: center !important;
}
@media (max-width: 575px) {
  .section__title {
    font-size: 33px;
  }
}
.section__title span {
  position: relative;
}
.section__title span img {
  position: absolute;
  left: -14px;
  bottom: 12px;
  z-index: -1;
  -webkit-animation: section-animation 3s infinite;
  animation: section-animation 3s infinite;
}
.section__title span.yellow-bg-big img {
  bottom: 15px;
  width: 120%;
  -webkit-animation: section-animation-2 3s infinite;
  animation: section-animation-2 3s infinite;
}
.section__title span.yellow-bg-sm img {
  bottom: 10px;
  width: 120%;
  -webkit-animation: section-animation-2 3s infinite;
  animation: section-animation-2 3s infinite;
}
.section__title-wrapper {
  position: relative;
  z-index: 1;
}
.section__title-wrapper p {
  font-size: 16px;
  color: #53545b;
}

@keyframes section-animation {
  0% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 100%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}
@keyframes section-animation-2 {
  0% {
    width: 0;
  }
  15% {
    width: 125%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 125%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}
/* basic pagination */
.basic-pagination ul li {
  display: inline-block;
  margin-right: 20px;
}
@media (max-width: 575px) {
  .basic-pagination ul li {
    margin-right: 5px;
  }
}
.basic-pagination ul li.prev a, .basic-pagination ul li.next a {
  width: inherit;
  height: inherit;
  line-height: inherit;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}
.basic-pagination ul li.prev a:hover, .basic-pagination ul li.next a:hover {
  color: #2b4eff;
  background: inherit;
}
.basic-pagination ul li.active a {
  color: #ffffff;
  background: #2b4eff;
  border-color: #2b4eff;
}
.basic-pagination ul li a {
  position: relative;
  overflow: hidden;
  background: transparent;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #eef0f6;
  color: #0e1133;
}
.basic-pagination ul li a:hover {
  background: #2b4eff;
  color: #ffffff;
  border-color: #2b4eff;
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.body-overlay:hover {
  cursor: pointer;
}

.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------*/
/*  19. BRAND CSS START
/*----------------------------------------*/
.brand__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 50px;
}
.brand__more {
  margin-top: 52px;
}
.brand__more .link-btn {
  color: #0e1133;
}
.brand__more .link-btn:hover {
  color: #2b4eff;
}

/*----------------------------------------*/
/*  23. ERROR CSS START
/*----------------------------------------*/
.error__title {
  font-size: 70px;
  line-height: 0.9;
  margin-bottom: 10px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error__title {
    font-size: 60px;
  }
}
@media (max-width: 575px) {
  .error__title {
    font-size: 37px;
  }
}
.error__content p {
  font-size: 20px;
  color: #53545b;
  margin-bottom: 45px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error__thumb img {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .error__thumb img {
    width: 100%;
  }
}

/*----------------------------------------*/
/*  24. LOGIN CSS START
/*----------------------------------------*/
.sign__wrapper {
  padding: 50px 70px;
  padding-bottom: 45px;
  margin: 0 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 40px 80px 0px rgba(2, 2, 26, 0.14);
  -moz-box-shadow: 0px 40px 80px 0px rgba(2, 2, 26, 0.14);
  box-shadow: 0px 40px 80px 0px rgba(2, 2, 26, 0.14);
  z-index: 11;
}
@media (max-width: 575px) {
  .sign__wrapper {
    margin: 0;
    padding: 30px 20px;
    padding-bottom: 25px;
  }
}
.sign__shape img {
  position: absolute;
  z-index: -1;
}
.sign__shape img.man-1 {
  left: 28%;
  top: 60%;
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sign__shape img.man-1 {
    left: 19%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sign__shape img.man-1 {
    left: 16%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sign__shape img.man-1 {
    left: 7%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.man-1 {
    left: 2%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.man-1 {
    left: 1%;
  }
}
@media (max-width: 575px) {
  .sign__shape img.man-1 {
    display: none;
  }
}
.sign__shape img.man-2 {
  right: 24%;
  top: 65%;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sign__shape img.man-2 {
    top: 55%;
    right: 20%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sign__shape img.man-2 {
    top: 60%;
    right: 15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sign__shape img.man-2 {
    top: 60%;
    right: 7%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.man-2 {
    top: 60%;
    right: 0%;
    z-index: 1;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.man-2 {
    top: 60%;
    right: 0%;
    z-index: 1;
  }
}
@media (max-width: 575px) {
  .sign__shape img.man-2 {
    display: none;
  }
}
.sign__shape img.man-2.man-22 {
  top: 50%;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sign__shape img.man-2.man-22 {
    top: 55%;
    right: 20%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sign__shape img.man-2.man-22 {
    top: 60%;
    right: 15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sign__shape img.man-2.man-22 {
    top: 60%;
    right: 7%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.man-2.man-22 {
    top: 60%;
    right: 0%;
    z-index: 1;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.man-2.man-22 {
    top: 60%;
    right: 0%;
    z-index: 1;
  }
}
.sign__shape img.circle {
  right: 32%;
  top: 38%;
  animation: signCircle 5s linear 0s infinite alternate;
  -webkit-animation: signCircle 5s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sign__shape img.circle {
    right: 25%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sign__shape img.circle {
    right: 22%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sign__shape img.circle {
    right: 11%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.circle {
    right: 4%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.circle {
    right: 2%;
  }
}
@media (max-width: 575px) {
  .sign__shape img.circle {
    display: none;
  }
}
.sign__shape img.dot {
  right: 34%;
  top: 43%;
  animation: signDot 5s linear 0s infinite alternate;
  -webkit-animation: signDot 5s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sign__shape img.dot {
    right: 29%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sign__shape img.dot {
    right: 26%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sign__shape img.dot {
    right: 18%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.dot {
    right: 5%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.dot {
    right: 3%;
  }
}
@media (max-width: 575px) {
  .sign__shape img.dot {
    display: none;
  }
}
.sign__shape img.bg {
  left: 33%;
  top: 26%;
  opacity: 0.06;
  max-width: 100%;
  animation: signBg 5s linear 0s infinite alternate;
  -webkit-animation: signBg 5s linear 0s infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.bg {
    left: 10%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.bg {
    left: 0%;
  }
}
@media (max-width: 575px) {
  .sign__shape img.bg {
    display: none;
  }
}
.sign__shape img.zigzag {
  left: 32%;
  top: 43%;
  animation: signZigzag 5s linear 0s infinite alternate;
  -webkit-animation: signZigzag 5s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sign__shape img.zigzag {
    left: 24%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sign__shape img.zigzag {
    left: 23%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sign__shape img.zigzag {
    left: 11%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.zigzag {
    left: 3%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.zigzag {
    left: 2%;
  }
}
@media (max-width: 575px) {
  .sign__shape img.zigzag {
    display: none;
  }
}
.sign__shape img.flower {
  bottom: 13%;
  right: 30%;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sign__shape img.flower {
    right: 24%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sign__shape img.flower {
    right: 24%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sign__shape img.flower {
    right: 12%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sign__shape img.flower {
    right: 3%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__shape img.flower {
    right: 1%;
  }
}
@media (max-width: 575px) {
  .sign__shape img.flower {
    right: 1%;
    bottom: 3%;
  }
}
.sign__header {
  padding: 0 35px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sign__header {
    padding: 0 10px;
  }
}
@media (max-width: 575px) {
  .sign__header {
    padding: 0px;
  }
}
.sign__header p {
  margin-bottom: 0;
  color: #7a797f;
  position: relative;
  padding: 0 40px;
  display: inline-block;
}
.sign__header p span {
  position: absolute;
  top: 40%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sign__header p span:last-child {
  left: auto;
  right: 0;
}
.sign__header p a:hover {
  color: #2b4eff;
}
.sign__social {
  display: block;
  height: 50px;
  background: #eeedf2;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: relative;
  line-height: 52px;
  padding-left: 23px;
  z-index: 1;
  overflow: hidden;
  font-size: 16px;
}
.sign__social::after {
  position: absolute;
  content: "";
  left: -55%;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 220px;
  width: 220px;
  background: #3360bd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
}
@media (max-width: 575px) {
  .sign__social::after {
    left: -62%;
  }
}
.sign__social i {
  color: #ffffff;
  font-size: 16px;
  margin-right: 50px;
}
@media (max-width: 575px) {
  .sign__social i {
    margin-right: 35px;
  }
}
.sign__social:hover {
  color: #ffffff;
}
.sign__social:hover::after {
  width: 100%;
  height: 150%;
  left: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.sign__social.g-plus::after {
  background: #e93e30;
}
.sign__form h5 {
  font-size: 16px;
  font-weight: 500;
  color: #0e1133;
  margin-bottom: 11px;
}
.sign__input {
  position: relative;
}
.sign__input input {
  width: 100%;
  height: 60px;
  line-height: 52px;
  padding: 0 50px;
  font-size: 14px;
  border: 2px solid transparent;
  background: #f6f6f7;
  color: #0e1133;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.sign__input input::placeholder {
  color: #8e8c94;
}
.sign__input input:focus {
  outline: none;
  background: #ffffff;
  border-color: #2b4eff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(8, 0, 42, 0.2);
  -moz-box-shadow: 0px 1px 4px 0px rgba(8, 0, 42, 0.2);
  box-shadow: 0px 1px 4px 0px rgba(8, 0, 42, 0.2);
}
.sign__input i {
  position: absolute;
  top: 51%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 25px;
  font-size: 14px;
  color: #8e8c94;
}
.sign__agree input {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  display: block;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid #7a797f;
  outline: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.sign__agree input:checked {
  position: relative;
  background-color: #2b4eff;
  border-color: transparent;
}
.sign__agree input:checked::after {
  box-sizing: border-box;
  content: "\f00c";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 10px;
  color: #ffffff;
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sign__agree input:hover {
  cursor: pointer;
}
.sign__agree label {
  padding-left: 10px;
  font-size: 14px;
}
.sign__agree label:hover {
  cursor: pointer;
}
.sign__agree label a {
  color: #0e1133;
  font-weight: 500;
}
.sign__agree label a:hover {
  color: #2b4eff;
}
.sign__forgot a {
  color: #7a797f;
}
.sign__forgot a:hover {
  color: #2b4eff;
}
.sign__new p {
  color: #7a797f;
  margin-bottom: 0;
}
.sign__new p a {
  color: #2b4eff;
}

@keyframes signDot {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
  }
}
@keyframes signZigzag {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
  }
}
@keyframes signCircle {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}